import shadows from "@material-ui/core/styles/shadows";
import { firebaseDatabase } from "./firebase";
import axios from "axios";
import { takeLatest, put, call, select, actionChannel } from "redux-saga/effects";

export const generateUUID = () => {
    var u = '', i = 0;
    var today = new Date();
    while (i++ < 36) {
        var c = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'[i - 1], r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        u += (c == '-' || c == '4') ? c : v.toString(16)
    }
    return u + "-" + today.getTime().toString();
}

export const getCatalog = async (id) => {
    const catalogRespose = firebaseDatabase.collection(`catalogos/${id}/data`);
    return catalogRespose;
};

export const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
}

export const exist = (array, value) => {
    var ban = false;
    array.forEach(element => {
        if (element.value === value) {
            ban = true;
            return;
        }
    });
    return ban;
}

export const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export const getAsyncCatalog = async (type) => {
    const catalogo = [];
    const catalogRespose = await firebaseDatabase.collection(`catalogos/${type}/data`);
    const collection = await catalogRespose.get();

    collection.docs.map(doc => {
        catalogo.push(doc.data());
    });

    return catalogo;
}

export const getAsyncCollection = async (type) => {
    var collection = false;
    const catalogRespose = await firebaseDatabase.collection(`catalogos`).doc(`${type}`).get();
    collection = catalogRespose.data();
    return collection;
}

export const emptyObject = (value) => {
    return typeof value !== 'object' && value !== undefined ? value : '';
}

export const emptyVacio = (value) => {
    return typeof value !== 'object' && value !== undefined && value.length < 1 ? true : false;
}

export const emptyVacioDisabled = (value) => {
    return typeof value !== 'object' && value !== undefined && value.length > 0 ? true : false;
}

export const validOportunidad = (value, items) => {
    let valid = false;
    if (value.consultaOportunidadResponse && value.consultaOportunidadResponse.Opportunity && value.consultaOportunidadResponse.Opportunity.LifeCycleStatusCode === "5") {
        const oportunidad = value.consultaOportunidadResponse.Opportunity;
        const codPlan = items.homeType === "urbano" ? "Z02" : "Z03";
        if (oportunidad.SalesCycle.SalesCycleCode !== codPlan) {
            valid = true;
        }
    }
    return valid;
}

export const emptyJson = (value) => {
    return value !== undefined && JSON.stringify(value) === '{}' ? true : false;
}

export const emptyObjectEmp = (value, replace) => {
    return typeof value !== 'object' && value !== undefined ? value : replace;
}

export const emptyCero = (value) => {
    return value !== '' && value !== undefined && value !== null ? value : '0'
}

export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export const removeAccents = (str) => {
    const acentos = { 'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U', '´': ' ', };
    console.log(str.split(''))
    return str.split('').map(letra => acentos[letra] || letra).join('').toString();
}

export const cortarMSM = (mens, limite) => {
    try {
        let newMsm = mens;
        if (mens && mens.length > limite) {
            newMsm = mens.substr(0, limite - 1)
        }
        return newMsm;
    } catch (error) {
        throw `cortarMSM - ${error}`

    }
}

export const letras_numbers = (str) => {
    //verifica si una caden esta compuesta solo por letras numeros y espacios
    var patt = new RegExp(/^[A-Za-z0-9\s]+$/g);
    return patt.test(str);
}

export const enviarEmail = (action) => {
    try {
        const { from, to, subject, message } = action
        const url = process.env.REACT_APP_URL_SEND_EMAIL_COMPANY;


        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            'Access-Control-Allow-Credentials': 'true',
            "Access-Control-Request-Headers": "access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,auth,authorization,cache-control,content-type,mode",
            'accept': "application/json, text/plain, */*",
            "Authorization": 'Basic Rk9STVVMQVJJT1NXRUI6WWsyMTkwX3c=',
            "content-type": "application/json;charset=iso-8859-1",
            "Cache-Control": "no-cache",
            'mode': "opaque ",
        };
        let body = {
            from,
            to,
            subject,
            message,
        }

        console.log(body, url)

        axios.create({
            headers,
        }).post(url, body)
            .then(response => {
                console.log(response.data)
            })
            .catch(err => {
                console.error('error invocando enviar email:' + err + " " + err.message)
                throw err;
            })

    } catch (error) {
        console.error("Function.enviarEmail", error);
    }
}

export const encode = (data) => {
    return Buffer.from(data, 'utf-8').toString('base64')
}

let sinDiacriticos = (function () {
    let de = 'ÁÃÀÄÂÉËÈÊÍÏÌÎÓÖÒÔÚÜÙÛÑÇáãàäâéëèêíïìîóöòôúüùûñç',
        a = 'AAAAAEEEEIIIIOOOOUUUUNCaaaaaeeeeiiiioooouuuunc',
        re = new RegExp('[' + de + ']', 'ug');

    return texto =>
        texto.replace(
            re,
            match => a.charAt(de.indexOf(match))
        );
})();

export const homologarParentezco = (catalog, payload) => {


    let res = "";
    const filtro = catalog.find(e => parseInt(e.value) === parseInt(payload));
    if (filtro) res = filtro.id_crm;


    return res;
};

export const conversionNumber = (value) => {
    var fin = value;
    if (value && value.length > 0 && value.includes("$")) {
        fin = ((value.replaceAll(",", "")).replaceAll(" ", "")).replaceAll("$", "");
    }
    return fin;
}

export const fontSizeCortar = (cadena) => {
    return cadena.length > 21 ? cadena.slice(0, 21) : cadena;
};

export const calculaCierre = (fina) => {
    return fina ? (0 + parseInt(emptyCero(fina?.totalahorroprevio)) + parseInt(emptyCero(fina?.totalrecursos)) + parseInt(emptyCero(fina?.valorsubcidiado))).toString() : "0";
};

export const dividePhone = (phone, clave) => {
    let response = ""
    const aux = phone.split("-");
    if (clave === "telefono1")
        response = aux[0];
    if (clave === "telefono2")
        if (aux.length > 1)
            response = aux[1];

    return response;
}

export const conversionSexo = (action) => {
    var res = { label: action, value: "" };
    if (action) {
        if (action === "M") {
            res.value = "2";
        }
        else if (action === "F") {
            res.value = "1";
        } else {
            res.value = "3";
        }
    }
    return res;
}

export const decode = (data) => {
    return Buffer.from(data, 'base64').toString('utf-8')
}

export const notificaFalloWBS = (action) => {
    try {
        const { body, webService, response } = action

        const from = "Notificaciones bandeja comercial";
        const to = `<jamu@comfenalcovalle.com.co>`;
        const subject = `${process.env.REACT_APP_TITLE_NOTIFICATIONS_EMAIL}`;
        const message = `<div><p>El web service <b>${webService}</b> presento una respuesta inesperada, a lo cual se requiere tomar control de la acción esperada</p><p>Cuerpo del webservice</p><p>${JSON.stringify(body)}</p><p>Respuesta del webservice</p><p>${JSON.stringify(response)}</p> </div>`
        const res = enviarEmail({ from, to, subject, message })

    } catch (error) {
        throw `saga.js - notificaFalloWBS ${error}`;
    }
}

export const cuantosSalarios = (salario, monto) => {
    return `${(parseInt(monto) / parseInt(salario)).toFixed(2)}`;
}

export function* consumeServiceGenerico(action) {
    try {
        const { body, url } = action;

        const request = { urlServicio: url, body: body }
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            'Access-Control-Allow-Credentials': 'true',
            "Access-Control-Request-Headers": "*",
            'accept': "application/json, text/plain, */*",
            "Authorization": 'Basic Rk9STVVMQVJJT1NXRUI6WWsyMTkwX3c=',
            "content-type": "application/json;charset=iso-8859-1",
            "Cache-Control": "no-cache",
            'mode': "opaque ",
        };

        const respues = yield call(axios.post, process.env.REACT_APP_FIREBASE_SEND_CONSUME_SERVICIOS_GENERICOS, request, headers);

        return respues;
    } catch (error) {
        console.error("Function.consumeServiceGenerico", error);
        throw `Function.consumeServiceGenerico - saga.js -${error}`;
    }
}

export function idDeparta(catalogo, id) {
    var idDepa = id
    catalogo.forEach(element => {
        if (element.value === id) {
            idDepa = element.id_depa;
        }
    });
    return idDepa;
}

export function crearCities(citys, idDepto) {
    var arrCitys = [];
    citys.forEach((element) => {
        if (element.id_depa === idDepto) {
            arrCitys.push({
                label: element.label,
                value: element.value,
            });
        }
    });
    return arrCitys;
}

export function getFechaOfDateV3() {

    let fecha = new Date();

    let dia = fecha.getDate();
    let mon = fecha.getMonth() + 1;
    if (fecha.getDate() < 10) {
        dia = `0${fecha.getDate()}`;
    }
    if (fecha.getMonth() < 10) {
        mon = `0${mon}`;
    }

    const hh = (fecha.getHours());
    const mmh = (fecha.getMinutes());

    let year = `${fecha.getFullYear()}`;

    return `${dia}-${mon}-${year.slice(2, 4)} ${hh}${mmh}`
}