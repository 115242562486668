import { ToysTwoTone } from '@material-ui/icons';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import React, { Component } from 'react';
import { conversionNumber, dividePhone, emptyJson, emptyObject } from "../../controller/utils";

export default class MyDocument extends Component {
    constructor(props) {
        super(props);
    }

    styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#fff'
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        },
        backgroundImg: {
            position: 'absolute',
            left: 0,
            top: 0
        },
        centerImage: {
            alignItems: "center",
            flexGrow: 1
        },
        image: {
            padding: 0,
            width: '100%',
            position: 'absolute',
            left: 0
        },
        imagePosition: (left, right, top, size, size2) => {
            return {
                left: left ? `${left}` : 'auto',
                right: right ? `${right}` : 'auto',
                top: top ? `${top}` : 'auto', // 175
                width: size ? size : '100%',
                height: size2 ? size2 : '100%',
                position: 'absolute'
            };
        },
        fontText: (left, right, top, size) => {
            return {
                left: left ? `${left}` : 'auto',
                right: right ? `${right}` : 'auto',
                top: top ? `${top}` : 'auto', // 175
                fontSize: size ? size : 9,
                position: 'absolute',
                fontWeight: 'bold'
            };
        },

    });
    fontSize(cadena) {
        return cadena.length > 60 ? 5
            : cadena.length > 50 ? 6 : cadena.length > 40 ? 7 : 8;
    };
    fontSizeM(cadena) {
        return cadena.length > 60 ? 4 : cadena.length > 50 ? 5
            : cadena.length > 40 ? 6 : cadena.length > 30 ? 7 : 8;
    };

    fontSizeA(cadena) {
        return cadena.length > 55 ? 2 : cadena.length > 41 ? 3 : cadena.length > 32 ? 4 : cadena.length > 25 ? 5
            : cadena.length > 21 ? 6 : cadena.length > 19 ? 7 : 8;
    };
    homologarEstadoCivil(action) {
        const estado = [
            { id: "3", homologa: "5" },
            { id: "4", homologa: "3" },
            { id: "5", homologa: "4" },
            { id: "6", homologa: "4" },
        ]
        var id = parseInt(action.id);
        estado.forEach(element => {
            if (parseInt(element.id) === id) {
                id = element.homologa;
                return;
            }
        })
        return id;
    }

    render() {
        const { data, id } = this.props;
        let nuevo = true;
        if (data?.actualizacion) {
            nuevo = false;
        }

        const tempFir = data.refFiles?.refStorage?.filter(e => e.classification === "firma");
        const refFirmas = tempFir !== undefined && tempFir.length > 0 ? tempFir : [];

        const oportunidad = data?.oportunidad;

        var informacionbasica = data?.step1?.informacionbasica;
        if (nuevo) informacionbasica = data?.step3?.informacionbasica;

        const datospostulante = data?.step1?.datospostulante;
        let condicionHogar = data?.step2?.condicionHogar;
        const inthogarpostulantes = data?.step2?.integrantesHogar?.inthogarpostulantes;
        const salarioAfiliado = condicionHogar.ingresos;
        const totalingresos = data?.step2?.totalingresos;
        const informacionpostulacion = data?.step3?.informacionpostulacion;
        const modalidadvivienda = data?.step3?.modalidadvivienda;
        const solucionconstrucion = data?.step3?.valorconstruccion;
        const ahorroprevio = data?.step4?.ahorroprevio;
        const financiaciontotal = data?.step4?.financiaciontotal;
        const valorSubsidiado = data?.valorSubsidioOtorgado;

        const recursoscomplementarios = data?.step4?.recursoscomplementarios;
        const totalahorroprevio = data?.step4?.totalahorroprevio;
        const totalrecursoscomplementarios = data?.step4?.totalrecursoscomplementarios;
        const nameEmpAfil = data?.step1?.informacionlaboral?.subsidioData?.name;
        const dirEmpAfil = data?.step1?.informacionlaboral?.subsidioData?.place;
        const phoneEmpAfil = dividePhone(emptyObject(datospostulante.companyPhone),"telefono2");
        var nameEmpLab = '';
        var dirEmpLab = '';
        var phoneEmpLab = '';
        if (data?.step1?.informacionlaboral?.laborandoSelect === "otraEmpresa") {
            nameEmpLab = data?.step1?.informacionlaboral?.companyJobName;
            dirEmpLab = data?.step1?.informacionlaboral?.companyJobAddress;
            phoneEmpLab = data?.step1?.informacionlaboral?.companyPhoneJob;
        } else {
            nameEmpLab = data?.step1?.informacionlaboral?.laborandoData?.name;
            dirEmpLab = data?.step1?.informacionlaboral?.laborandoData?.place;
            phoneEmpLab = data?.step1?.informacionlaboral?.laborandoData?.phone;
        }
        const ingreGrupo = parseInt(totalingresos.totalingresos) - parseInt(salarioAfiliado)
        var valorSFV = totalingresos.totalingresos;
        if (parseInt(totalingresos?.salarioreportafiliacion) > parseInt(salarioAfiliado)) {
            valorSFV = (parseInt(totalingresos?.salarioreportafiliacion) + ingreGrupo).toString();
        }

        var firmaPostu = "##"
        if (refFirmas) {
            refFirmas.map((item, j) => {
                if (item.nameDoc === "firma2") {
                    firmaPostu = `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/${item.refFile}`;
                }
            });
        }
        var valorViviendaAdq = [];
        if (informacionbasica?.homeModeType === '1') {
            valorViviendaAdq = financiaciontotal?.valorvivienda?.split('');
        }

        var valorTotalAvaluo = [];
        if (informacionbasica?.homeModeType === '2' || informacionbasica?.homeModeType === '3') {
            valorTotalAvaluo = (parseInt(solucionconstrucion?.avaluocatastral) + parseInt(solucionconstrucion?.presupuesto)).toString().split('');
        }


        const totalahorropreviosplit = totalahorroprevio?.totalAhorro?.split('');
        const totalrecursoscomplementariossplit = totalrecursoscomplementarios?.totalComple?.split('');
        const cierresplit = financiaciontotal?.cierre?.split('');
        const valorsubsidiosolicitadosplit = valorSubsidiado ? valorSubsidiado?.split('') : financiaciontotal?.valorsubcidiado?.split('');
        const valorviviendaadquirirsplit = valorViviendaAdq;
        const presupuestosplit = solucionconstrucion?.presupuesto?.split('');
        const valorSFVsplit = data.valorSFV?.split('');
        const avaluocatastralsplit = solucionconstrucion?.avaluocatastral?.split('');
        const totalavaluosplit = valorTotalAvaluo;

        let alignTipoProyecto = 40;

        if (modalidadvivienda?.projecttype === '3') {
            alignTipoProyecto = 198;
        } else if (modalidadvivienda?.projecttype === '2') {
            alignTipoProyecto = 435;
        } else if (modalidadvivienda?.projecttype === '4') {
            alignTipoProyecto = 198;
        } else {
            alignTipoProyecto = 40;
        }

        let alignTipoHome = 30;
        let alignTipoHome2 = 0;
        if (informacionbasica?.homeType === 'rural' || informacionbasica?.homeType === 'Rural') {
            alignTipoHome = 504;
            alignTipoHome2 = 448;
        } else {
            alignTipoHome = 452;
            alignTipoHome2 = 373;
        }

        let alignLote = 30;

        if (modalidadvivienda?.propiedadlote === '1') {
            alignLote = 170;
        } else if (modalidadvivienda?.propiedadlote === '2') {
            alignLote = 291;
        } else {
            alignLote = 412;
        }

        const splitDate = condicionHogar?.birthdate?.split('-');
        const amount = condicionHogar?.ingresos ? condicionHogar?.ingresos : 0;
        const options1 = { style: 'currency', currency: 'COP' };
        const numberFormat1 = new Intl.NumberFormat('es-CO', options1);
        const ahorroprevioSplitted = financiaciontotal?.totalahorroprevio?.split('');
        const complementoSplitted = financiaciontotal?.totalrecursos?.split('');
        var ban1 = false;
        var ban2 = false;
        var banconyugue = false;
        var banconyugueFirma = false;
        var srcFirmaConyugue = "##";
        var nameConyugue = '';
        var idConyugue = '';

        let nombreCaja = '';

        const namePostulante = `${datospostulante?.lastName ? datospostulante?.lastName : ''} ${datospostulante?.firstName ? datospostulante?.firstName : ''} ${datospostulante?.middleName ? datospostulante?.middleName : ''} `

        return (
            <React.Fragment>
                <Document>
                    <Page size="A4" style={this.styles.page}>
                        <View style={this.styles.section}>
                            <Image style={this.styles.image} src="/af-formulario-1.jpg" />
                            <Image style={this.styles.imagePosition(175, false, 440.5, '68px', '20px')} src={firmaPostu}  onError={(error) => console.error(firmaPostu,"Image failed to load: ", error)} />
                            <Text style={this.styles.fontText('78%', false, 128, 8)}>{informacionbasica?.entidadSubsidio}</Text>
                            <Text style={this.styles.fontText('80%', false, 77, 7)}>{oportunidad}</Text>

                            { /* INFORMACIÓN BÁSICA */}

                            <Text style={informacionbasica?.subsidio === '1' ? this.styles.fontText(false, 209, 104, 9) : this.styles.fontText(false, 165, 104, 9)}>x</Text>
                            {nuevo && <Text style={this.styles.fontText(83, 209, 143, 9)}>x</Text>}
                            <Text style={this.styles.fontText(alignTipoHome2, false, 143, 8)}>{informacionbasica?.homeType ? "x" : ""}</Text>
                            {/* <Text style={informacionbasica?.homeType === 'urbano' ? this.styles.fontText(false, 198, 146, 9) : this.styles.fontText(false, 122, 146, 9)}>x</Text> */}

                            {informacionbasica?.homeModeType == '1' && <Text style={this.styles.fontText(179, false, 158, false)}>x</Text>}
                            {informacionbasica?.homeModeType == '2' && <Text style={this.styles.fontText(298, false, 158, false)}>x</Text>}
                            {informacionbasica?.homeModeType == '3' && <Text style={this.styles.fontText(413, false, 158, false)}>x</Text>}
                            {informacionbasica?.homeModeType == '4' && <Text style={this.styles.fontText(504, false, 158, false)}>x</Text>}

                            {informacionbasica?.postulanteType == '1' && <Text style={this.styles.fontText(138, false, 173, false)}>x</Text>}
                            {informacionbasica?.postulanteType == '2' && <Text style={this.styles.fontText(254, false, 173, false)}>x</Text>}
                            {informacionbasica?.postulanteType == '3' && <Text style={this.styles.fontText(385, false, 173, false)}>x</Text>}
                            {informacionbasica?.postulanteType == '4' && <Text style={this.styles.fontText(505, false, 173, false)}>x</Text>}

                            { /* DATOS POSTULANTE  namecity namedepartament */}

                            <Text style={this.styles.fontText(22, false, 209, this.fontSize(namePostulante))}>{namePostulante}</Text>
                            <Text style={this.styles.fontText(250, false, 209, 7)}>{datospostulante?.numberId}</Text>
                            <Text style={this.styles.fontText(411, false, 209, this.fontSize(datospostulante?.nameidPlace))}>{datospostulante?.nameidPlace}</Text>
                            <Text style={this.styles.fontText(25, false, 224, this.fontSize(datospostulante?.currentAddress))}>{datospostulante?.currentAddress}</Text>
                            <Text style={this.styles.fontText(25, false, 239, this.fontSize(datospostulante?.namedepartament))}>{datospostulante?.namedepartament}</Text>
                            <Text style={this.styles.fontText(100, false, 239, 7)}>{datospostulante?.namecity}</Text>

                            <Text style={this.styles.fontText(250, false, 224, 7)}>{datospostulante?.companyEmail}</Text>
                            <Text style={this.styles.fontText(250, false, 239, this.fontSizeM(datospostulante?.hood))}>{datospostulante?.hood}</Text>
                            <Text style={this.styles.fontText(411, false, 239, 7)}>{ dividePhone(emptyObject(datospostulante.companyPhone),"telefono1")}</Text>
                            <Text style={this.styles.fontText(25, false, 256, this.fontSize(nameEmpAfil))}>{nameEmpAfil}</Text>
                            <Text style={this.styles.fontText(250, false, 256, this.fontSizeM(dirEmpAfil))}>{dirEmpAfil}</Text>
                            <Text style={this.styles.fontText(411, false, 256, 7)}>{phoneEmpAfil}</Text>
                            <Text style={this.styles.fontText(25, false, 272, this.fontSize(nameEmpLab))}>{nameEmpLab}</Text>
                            <Text style={this.styles.fontText(250, false, 272, this.fontSizeM(dirEmpLab))}>{dirEmpLab}</Text>
                            <Text style={this.styles.fontText(411, false, 272, 7)}>{phoneEmpLab}</Text>

                            { /* CONDICIÓN HOGAR */}

                            <Text style={this.styles.fontText(21, false, 450, 6)}>{condicionHogar?.companyName}</Text>
                            <Text style={this.styles.fontText(245, false, 450, 8)}>{splitDate && splitDate.length > 0 ? splitDate[0] : ''}</Text>
                            <Text style={this.styles.fontText(270, false, 450, 8)}>{splitDate && splitDate.length > 1 ? splitDate[1] : ''}</Text>
                            <Text style={this.styles.fontText(290, false, 450, 8)}>{splitDate && splitDate.length > 2 ? splitDate[2] : ''}</Text>
                            <Text style={this.styles.fontText(305, false, 450, 6)}>{condicionHogar?.documentType}</Text>
                            <Text style={this.styles.fontText(320, false, 450, 8)}>{condicionHogar?.numDocJefe}</Text>

                            <Text style={this.styles.fontText(386, false, 450, 8)}>{condicionHogar?.sex}</Text>
                            <Text style={this.styles.fontText(403, false, 450, 8)}>{this.homologarEstadoCivil({ id: condicionHogar.maritalstatusJefe })}</Text>
                            <Text style={this.styles.fontText(419, false, 450, 8)}>{condicionHogar?.parentezcoJefe}</Text>
                            <Text style={this.styles.fontText(433, false, 450, 8)}>{condicionHogar?.jobJefeHogar}</Text>
                            <Text style={this.styles.fontText(450, false, 450, 8)}>{condicionHogar?.special}</Text>
                            <Text style={this.styles.fontText(469, false, 450, 8)}>{condicionHogar?.tipostulante}</Text>
                            <Text style={this.styles.fontText(false, 43, 450, 6)}>{numberFormat1.format(amount)}</Text>

                            { /* INTEGRANTES HOGAR POSTULANTE */}
                            {

                                inthogarpostulantes?.map((post, i) => {
                                    if (i < 5) {
                                        const dateSplitted = post.birthdatePostu?.split('-');
                                        const amountPostu = post.ingresosMensual && post.ingresosMensual.length > 0 ? conversionNumber(post.ingresosMensual) : 0;
                                        const postuRow = 472 + (12 * i);
                                        const postuRowI = 470 + (12 * i);
                                        nombreCaja = post.nombrecajaPostu ? post.nombrecajaPostu : nombreCaja;
                                        var srcFirma = "##";
                                        if (refFirmas) {

                                            refFirmas.map((item, j) => {

                                                if (item.nameDoc !== "firma2") {
                                                    const temp = item.nameDoc.split("[");
                                                    const temp2 = temp[1].split("]");
                                                    if (temp2[0] === (i).toString()) {
                                                        srcFirma = `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/${item.refFile}`;
                                                    }
                                                    if (!banconyugueFirma && post.relativePostu === "2") {
                                                        banconyugueFirma = true;
                                                        srcFirmaConyugue = `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/${item.refFile}`;

                                                    }
                                                }

                                            });
                                        }
                                        if (!banconyugue && post.relativePostu === "2") {
                                            banconyugue = true;
                                            nameConyugue = post.namePostu;
                                            idConyugue = post.idPostu;

                                        }



                                        return (
                                            <React.Fragment>
                                                <Text style={this.styles.fontText(21, false, postuRow, 6)}>{post.namePostu}</Text>
                                                <Image style={this.styles.imagePosition(175, false, postuRowI, "68px", '11px')} src={srcFirma}  onError={(error) => console.error(srcFirma,"Image failed to load: ", error)} />
                                                <Text style={this.styles.fontText(245, false, postuRow, 8)}>{dateSplitted && dateSplitted.length > 0 ? dateSplitted[0] : ''}</Text>
                                                <Text style={this.styles.fontText(270, false, postuRow, 8)}>{dateSplitted && dateSplitted.length > 1 ? dateSplitted[1] : ''}</Text>
                                                <Text style={this.styles.fontText(290, false, postuRow, 8)}>{dateSplitted && dateSplitted.length > 2 ? dateSplitted[2] : ''}</Text>
                                                <Text style={this.styles.fontText(305, false, postuRow, 5)}>{post.documentTypePostu}</Text>
                                                <Text style={this.styles.fontText(320, false, postuRow, 8)}>{post.idPostu}</Text>
                                                <Text style={this.styles.fontText(386, false, postuRow, 8)}>{post.sexPostu}</Text>

                                                <Text style={this.styles.fontText(403, false, postuRow, 8)}>{this.homologarEstadoCivil({ id: post.maritalstatusPostu })}</Text>
                                                <Text style={this.styles.fontText(419, false, postuRow, 8)}>{post.relativePostu}</Text>
                                                <Text style={this.styles.fontText(433, false, postuRow, 8)}>{post.jobPostu}</Text>
                                                <Text style={this.styles.fontText(450, false, postuRow, 8)}>{post.specialPostu}</Text>
                                                <Text style={this.styles.fontText(469, false, postuRow, 8)}>{post.postulantePostu}</Text>
                                                <Text style={this.styles.fontText(false, 43, postuRow, 6)}>{numberFormat1.format(amountPostu)}</Text>
                                            </React.Fragment>
                                        )
                                    }
                                })
                            }
                            { /* TOTAL INGRESOS */}
                            <Text style={this.styles.fontText(90, false, 527, 8)}>{totalingresos?.observaciones}</Text>
                            <Text style={this.styles.fontText(false, 43, 531, 6)}>{(numberFormat1.format(totalingresos?.totalingresos)).split("$")[1]}</Text>
                            <Text style={this.styles.fontText(190, false, 544, 6)}>{(numberFormat1.format(totalingresos?.salarioreportafiliacion)).split("$")[1]}</Text>
                            <Text style={this.styles.fontText(false, 43, 544, 6)}>{(numberFormat1.format(valorSFV)).split("$")[1]}</Text>
                            <Text style={this.styles.fontText(368, false, 560, 6)}>{nombreCaja}</Text>

                            {/* INFORMACIÓN DE LA POSTULACIÓN informacionpostulacion */}
                            <Text style={this.styles.fontText(alignTipoHome, false, 589, 8)}>{informacionbasica?.homeType ? "x" : ""}</Text>
                            <Text style={this.styles.fontText(90, false, 589, 8)}>{informacionpostulacion?.projectname}</Text>
                            <Text style={this.styles.fontText(90, false, 603, 8)}>{informacionpostulacion?.ofername}</Text>
                            <Text style={this.styles.fontText(340, false, 607, 6)}>{informacionpostulacion?.namedepartamentPostu}</Text>
                            <Text style={this.styles.fontText(437, false, 607, 6)}>{informacionpostulacion?.namecityPostu}</Text>

                            {
                                /* MODALIDAD DE VIVIENDA */
                            }
                            <Text style={this.styles.fontText(alignTipoProyecto, false, 637, 8)}>{modalidadvivienda?.projecttype ? "x" : ""}</Text>
                            <Text style={this.styles.fontText(alignLote, false, 653, 8)}>{modalidadvivienda?.propiedadlote ? "x" : ""}</Text>
                            <Text style={this.styles.fontText(42, false, 672, 7)}>{modalidadvivienda?.loteaddress}</Text>
                            {
                                /*
                                */
                            }
                            <Text style={this.styles.fontText(280, false, 672, 7)}>{modalidadvivienda?.namedepartamentoModalidad}</Text>
                            <Text style={this.styles.fontText(385, false, 672, 7)}>{modalidadvivienda?.namecityModalidad}</Text>
                            <Text style={this.styles.fontText(42, false, 690, 7)}>{modalidadvivienda?.urbanizacion}</Text>
                            <Text style={this.styles.fontText(305, false, 690, 7)}>{modalidadvivienda?.apple}</Text>
                            <Text style={this.styles.fontText(385, false, 690, 7)}>{modalidadvivienda?.lotenumber}</Text>
                            <Text style={this.styles.fontText(42, false, 707, 7)}>{modalidadvivienda?.barrioModalidad}</Text>
                            <Text style={this.styles.fontText(175, false, 707, 7)}>{modalidadvivienda?.licencia}</Text>
                            <Text style={this.styles.fontText(385, false, 707, 7)}>{modalidadvivienda?.vencimientoLicencia}</Text>
                            <Text style={this.styles.fontText(42, false, 726, 7)}>{modalidadvivienda?.escritura}</Text>
                            <Text style={this.styles.fontText(135, false, 726, 7)}>{modalidadvivienda?.escriturafecha}</Text>
                            <Text style={this.styles.fontText(247, false, 726, 7)}>{modalidadvivienda?.predioaddress}</Text>
                            <Text style={this.styles.fontText(385, false, 726, 7)}>{modalidadvivienda?.registertestdate}</Text>
                        </View>
                    </Page>
                    <Page size="A4" style={this.styles.page}>
                        <View style={this.styles.section}>
                            <Image style={this.styles.image} src="/af-formulario-2.jpg" />
                            {

                                !emptyJson(ahorroprevio) && ahorroprevio?.map((ahorro, i) => {
                                    const dateSplitted = ahorro.openDateA?.label?.split('-');
                                    const moneyFormatted = ahorro.safeValueA?.value.split('');
                                    const moneyRow = 72 + (i * 10);
                                    if (ahorro.tiporecursoA.value === "1" && !ban1) {
                                        ban1 = true;
                                        return (
                                            <React.Fragment>
                                                {/* <Text style={this.styles.fontText(20, false, moneyRow, 7)}>{ahorro?.tiporecursoA?.label}</Text> */}
                                                <Text style={this.styles.fontText(180, false, 72, this.fontSizeA(ahorro?.entityA?.label))}>{ahorro?.entityA?.label}</Text>
                                                <Text style={this.styles.fontText(288, false, 72, 7)}>{dateSplitted[2]}</Text>
                                                <Text style={this.styles.fontText(302, false, 72, 7)}>{dateSplitted[1]}</Text>
                                                <Text style={this.styles.fontText(317, false, 72, 7)}>{dateSplitted[0]}</Text>
                                                {
                                                    moneyFormatted.map((money, i) => {
                                                        var j = 0;
                                                        if (i > 5) {
                                                            j = 25;
                                                        } else if (i > 2) { j = 15; }
                                                        return <Text style={this.styles.fontText(false, 72 + j + (i * 15), 72, 7)}>{moneyFormatted[moneyFormatted.length - 1 - i]}</Text>

                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    if (ahorro.tiporecursoA.value === "1" && ban1) {
                                        ban1 = true;
                                        return (
                                            <React.Fragment>
                                                {/* <Text style={this.styles.fontText(20, false, moneyRow, 7)}>{ahorro?.tiporecursoA?.label}</Text> */}
                                                <Text style={this.styles.fontText(180, false, 82, this.fontSizeA(ahorro?.entityA?.label))}>{ahorro?.entityA?.label}</Text>
                                                <Text style={this.styles.fontText(288, false, 82, 7)}>{dateSplitted[2]}</Text>
                                                <Text style={this.styles.fontText(302, false, 82, 7)}>{dateSplitted[1]}</Text>
                                                <Text style={this.styles.fontText(317, false, 82, 7)}>{dateSplitted[0]}</Text>
                                                {
                                                    moneyFormatted.map((money, i) => {
                                                        var j = 0;
                                                        if (i > 5) {
                                                            j = 25;
                                                        } else if (i > 2) { j = 15; }
                                                        return <Text style={this.styles.fontText(false, 72 + j + (i * 15), 82, 7)}>{moneyFormatted[moneyFormatted.length - 1 - i]}</Text>

                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    if (ahorro.tiporecursoA.value === "2" && !ban2) {
                                        ban2 = true;
                                        return (
                                            <React.Fragment>
                                                {/* <Text style={this.styles.fontText(20, false, moneyRow, 7)}>{ahorro?.tiporecursoA?.label}</Text> */}
                                                <Text style={this.styles.fontText(180, false, 92, this.fontSizeA(ahorro?.entityA?.label))}>{ahorro?.entityA?.label}</Text>
                                                <Text style={this.styles.fontText(288, false, 92, 7)}>{dateSplitted[2]}</Text>
                                                <Text style={this.styles.fontText(302, false, 92, 7)}>{dateSplitted[1]}</Text>
                                                <Text style={this.styles.fontText(317, false, 92, 7)}>{dateSplitted[0]}</Text>
                                                {
                                                    moneyFormatted.map((money, i) => {
                                                        var j = 0;
                                                        if (i > 5) {
                                                            j = 25;
                                                        } else if (i > 2) { j = 15; }
                                                        return <Text style={this.styles.fontText(false, 72 + j + (i * 15), 92, 7)}>{moneyFormatted[moneyFormatted.length - 1 - i]}</Text>

                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    if (ahorro.tiporecursoA.value === "2" && ban2) {

                                        return (
                                            <React.Fragment>
                                                {/* <Text style={this.styles.fontText(20, false, moneyRow, 7)}>{ahorro?.tiporecursoA?.label}</Text> */}
                                                <Text style={this.styles.fontText(180, false, 102, this.fontSizeA(ahorro?.entityA?.label))}>{ahorro?.entityA?.label}</Text>
                                                <Text style={this.styles.fontText(288, false, 102, 7)}>{dateSplitted[2]}</Text>
                                                <Text style={this.styles.fontText(302, false, 102, 7)}>{dateSplitted[1]}</Text>
                                                <Text style={this.styles.fontText(317, false, 102, 7)}>{dateSplitted[0]}</Text>
                                                {
                                                    moneyFormatted.map((money, i) => {
                                                        var j = 0;
                                                        if (i > 5) {
                                                            j = 25;
                                                        } else if (i > 2) { j = 15; }
                                                        return <Text style={this.styles.fontText(false, 72 + j + (i * 15), 102, 7)}>{moneyFormatted[moneyFormatted.length - 1 - i]}</Text>

                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    if (ahorro.tiporecursoA.value === "3") {
                                        return (
                                            <React.Fragment>
                                                {/* <Text style={this.styles.fontText(20, false, moneyRow, 7)}>{ahorro?.tiporecursoA?.label}</Text> */}
                                                <Text style={this.styles.fontText(180, false, 112, this.fontSizeA(ahorro?.entityA?.label))}>{ahorro?.entityA?.label}</Text>
                                                <Text style={this.styles.fontText(288, false, 112, 7)}>{dateSplitted[2]}</Text>
                                                <Text style={this.styles.fontText(302, false, 112, 7)}>{dateSplitted[1]}</Text>
                                                <Text style={this.styles.fontText(317, false, 112, 7)}>{dateSplitted[0]}</Text>
                                                {
                                                    moneyFormatted.map((money, i) => {
                                                        var j = 0;
                                                        if (i > 5) {
                                                            j = 25;
                                                        } else if (i > 2) { j = 15; }
                                                        return <Text style={this.styles.fontText(false, 72 + j + (i * 15), 112, 7)}>{moneyFormatted[moneyFormatted.length - 1 - i]}</Text>

                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    if (ahorro.tiporecursoA.value === "4") {
                                        return (
                                            <React.Fragment>
                                                {/* <Text style={this.styles.fontText(20, false, moneyRow, 7)}>{ahorro?.tiporecursoA?.label}</Text> */}
                                                <Text style={this.styles.fontText(180, false, 122, this.fontSizeA(ahorro?.entityA?.label))}>{ahorro?.entityA?.label}</Text>
                                                <Text style={this.styles.fontText(288, false, 122, 7)}>{dateSplitted[2]}</Text>
                                                <Text style={this.styles.fontText(302, false, 122, 7)}>{dateSplitted[1]}</Text>
                                                <Text style={this.styles.fontText(317, false, 122, 7)}>{dateSplitted[0]}</Text>
                                                {
                                                    moneyFormatted.map((money, i) => {
                                                        var j = 0;
                                                        if (i > 5) {
                                                            j = 25;
                                                        } else if (i > 2) { j = 15; }
                                                        return <Text style={this.styles.fontText(false, 72 + j + (i * 15), 122, 7)}>{moneyFormatted[moneyFormatted.length - 1 - i]}</Text>

                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    if (ahorro.tiporecursoA.value === "5") {
                                        return (
                                            <React.Fragment>
                                                {/* <Text style={this.styles.fontText(20, false, moneyRow, 7)}>{ahorro?.tiporecursoA?.label}</Text> */}
                                                <Text style={this.styles.fontText(180, false, 140, this.fontSizeA(ahorro?.entityA?.label))}>{ahorro?.entityA?.label}</Text>
                                                <Text style={this.styles.fontText(288, false, 140, 7)}>{dateSplitted[2]}</Text>
                                                <Text style={this.styles.fontText(302, false, 140, 7)}>{dateSplitted[1]}</Text>
                                                <Text style={this.styles.fontText(317, false, 140, 7)}>{dateSplitted[0]}</Text>
                                                {
                                                    moneyFormatted.map((money, i) => {
                                                        var j = 0;
                                                        if (i > 5) {
                                                            j = 25;
                                                        } else if (i > 2) { j = 15; }
                                                        return <Text style={this.styles.fontText(false, 72 + j + (i * 15), 140, 7)}>{moneyFormatted[moneyFormatted.length - 1 - i]}</Text>

                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    if (ahorro.tiporecursoA.value === "6") {
                                        return (
                                            <React.Fragment>
                                                {/* <Text style={this.styles.fontText(20, false, moneyRow, 7)}>{ahorro?.tiporecursoA?.label}</Text> */}
                                                <Text style={this.styles.fontText(180, false, 150, this.fontSizeA(ahorro?.entityA?.label))}>{ahorro?.entityA?.label}</Text>
                                                <Text style={this.styles.fontText(288, false, 150, 7)}>{dateSplitted[2]}</Text>
                                                <Text style={this.styles.fontText(302, false, 150, 7)}>{dateSplitted[1]}</Text>
                                                <Text style={this.styles.fontText(317, false, 150, 7)}>{dateSplitted[0]}</Text>
                                                {
                                                    moneyFormatted.map((money, i) => {
                                                        var j = 0;
                                                        if (i > 5) {
                                                            j = 25;
                                                        } else if (i > 2) { j = 15; }
                                                        return <Text style={this.styles.fontText(false, 72 + j + (i * 15), 150, 7)}>{moneyFormatted[moneyFormatted.length - 1 - i]}</Text>

                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    if (ahorro.tiporecursoA.value === "7") {
                                        return (
                                            <React.Fragment>
                                                {/* <Text style={this.styles.fontText(20, false, moneyRow, 7)}>{ahorro?.tiporecursoA?.label}</Text> */}
                                                <Text style={this.styles.fontText(180, false, 160, this.fontSizeA(ahorro?.entityA?.label))}>{ahorro?.entityA?.label}</Text>
                                                <Text style={this.styles.fontText(288, false, 160, 7)}>{dateSplitted[2]}</Text>
                                                <Text style={this.styles.fontText(302, false, 160, 7)}>{dateSplitted[1]}</Text>
                                                <Text style={this.styles.fontText(317, false, 160, 7)}>{dateSplitted[0]}</Text>
                                                {
                                                    moneyFormatted.map((money, i) => {
                                                        var j = 0;
                                                        if (i > 5) {
                                                            j = 25;
                                                        } else if (i > 2) { j = 15; }
                                                        return <Text style={this.styles.fontText(false, 72 + j + (i * 15), 160, 7)}>{moneyFormatted[moneyFormatted.length - 1 - i]}</Text>

                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    if (ahorro.tiporecursoA.value === "8") {
                                        return (
                                            <React.Fragment>
                                                {/* <Text style={this.styles.fontText(20, false, moneyRow, 7)}>{ahorro?.tiporecursoA?.label}</Text> */}
                                                <Text style={this.styles.fontText(180, false, 170, this.fontSizeA(ahorro?.entityA?.label))}>{ahorro?.entityA?.label}</Text>
                                                <Text style={this.styles.fontText(288, false, 170, 7)}>{dateSplitted[2]}</Text>
                                                <Text style={this.styles.fontText(302, false, 170, 7)}>{dateSplitted[1]}</Text>
                                                <Text style={this.styles.fontText(317, false, 170, 7)}>{dateSplitted[0]}</Text>
                                                {
                                                    moneyFormatted.map((money, i) => {
                                                        var j = 0;
                                                        if (i > 5) {
                                                            j = 25;
                                                        } else if (i > 2) { j = 15; }
                                                        return <Text style={this.styles.fontText(false, 72 + j + (i * 15), 170, 7)}>{moneyFormatted[moneyFormatted.length - 1 - i]}</Text>

                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    if (ahorro.tiporecursoA.value === "9") {
                                        return (
                                            <React.Fragment>
                                                <Text style={this.styles.fontText(108, false, 180, 6)}>{ahorro?.tiporecursoA?.label}</Text>
                                                <Text style={this.styles.fontText(180, false, 180, this.fontSizeA(ahorro?.entityA?.label))}>{ahorro?.entityA?.label}</Text>
                                                <Text style={this.styles.fontText(288, false, 180, 7)}>{dateSplitted[2]}</Text>
                                                <Text style={this.styles.fontText(302, false, 180, 7)}>{dateSplitted[1]}</Text>
                                                <Text style={this.styles.fontText(317, false, 180, 7)}>{dateSplitted[0]}</Text>
                                                {
                                                    moneyFormatted.map((money, i) => {
                                                        var j = 0;
                                                        if (i > 5) {
                                                            j = 25;
                                                        } else if (i > 2) { j = 15; }
                                                        return <Text style={this.styles.fontText(false, 72 + j + (i * 15), 180, 7)}>{moneyFormatted[moneyFormatted.length - 1 - i]}</Text>

                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    }

                                    // return (
                                    //     <React.Fragment> 
                                    //         {/* <Text style={this.styles.fontText(20, false, moneyRow, 7)}>{ahorro?.tiporecursoA?.label}</Text> */}
                                    //         <Text style={this.styles.fontText(180, false, moneyRow, 7)}>{ahorro?.entityA?.label}</Text>
                                    //         <Text style={this.styles.fontText(288, false, moneyRow, 7)}>{dateSplitted[2]}</Text>
                                    //         <Text style={this.styles.fontText(302, false, moneyRow, 7)}>{dateSplitted[1]}</Text>
                                    //         <Text style={this.styles.fontText(317, false, moneyRow, 7)}>{dateSplitted[0]}</Text>
                                    //         {
                                    //             moneyFormatted.map((money, i) => {
                                    //                 return <Text style={this.styles.fontText(false, 73 + (i*15), moneyRow, 7)}>{moneyFormatted[moneyFormatted.length - 1 - i]}</Text> 
                                    //             })
                                    //         }
                                    //     </React.Fragment>
                                    // )
                                })
                            }
                            {
                                ahorroprevioSplitted?.map((previo, i) => {
                                    var j = 0;
                                    if (i > 5) {
                                        j = 25;
                                    } else if (i > 2) { j = 15; }
                                    return <Text style={this.styles.fontText(false, 72 + j + (i * 15), 188, 7)}>{ahorroprevioSplitted[ahorroprevioSplitted.length - 1 - i]}</Text>
                                })
                            }

                            {
                                !emptyJson(recursoscomplementarios) && recursoscomplementarios?.map((complemento, i) => {
                                    // const valuer = complemento?.safeValueR?.value?.split('');
                                    // const valuerow = 230 + (i * 10);
                                    const dateSplitted = complemento.openDateR?.label?.split('-');
                                    const moneyFormatted = complemento.safeValueR?.value.split('');


                                    if (complemento.tiporecursoR.value === "1") {
                                        return (
                                            <React.Fragment>
                                                {/* <Text style={this.styles.fontText(20, false, 164, 7)}>{ahorro?.tiporecursoA?.label}</Text> */}
                                                <Text style={this.styles.fontText(180, false, 226, this.fontSizeA(complemento?.entityR?.label))}>{complemento?.entityR?.label}</Text>
                                                <Text style={this.styles.fontText(288, false, 226, 7)}>{dateSplitted[2]}</Text>
                                                <Text style={this.styles.fontText(302, false, 226, 7)}>{dateSplitted[1]}</Text>
                                                <Text style={this.styles.fontText(317, false, 226, 7)}>{dateSplitted[0]}</Text>
                                                {
                                                    moneyFormatted.map((money, i) => {
                                                        var j = 0;
                                                        if (i > 5) {
                                                            j = 25;
                                                        } else if (i > 2) { j = 15; }
                                                        return <Text style={this.styles.fontText(false, 72 + j + (i * 15), 226, 7)}>{moneyFormatted[moneyFormatted.length - 1 - i]}</Text>

                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    if (complemento.tiporecursoR.value === "2") {
                                        return (
                                            <React.Fragment>
                                                {/* <Text style={this.styles.fontText(20, false, 164, 7)}>{ahorro?.tiporecursoA?.label}</Text> */}
                                                <Text style={this.styles.fontText(180, false, 240, this.fontSizeA(complemento?.entityR?.label))}>{complemento?.entityR?.label}</Text>
                                                <Text style={this.styles.fontText(288, false, 240, 7)}>{dateSplitted[2]}</Text>
                                                <Text style={this.styles.fontText(302, false, 240, 7)}>{dateSplitted[1]}</Text>
                                                <Text style={this.styles.fontText(317, false, 240, 7)}>{dateSplitted[0]}</Text>
                                                {
                                                    moneyFormatted.map((money, i) => {
                                                        var j = 0;
                                                        if (i > 5) {
                                                            j = 25;
                                                        } else if (i > 2) { j = 15; }
                                                        return <Text style={this.styles.fontText(false, 72 + j + (i * 15), 240, 7)}>{moneyFormatted[moneyFormatted.length - 1 - i]}</Text>

                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    if (complemento.tiporecursoR.value === "3") {
                                        return (
                                            <React.Fragment>
                                                {/* <Text style={this.styles.fontText(20, false, 164, 7)}>{ahorro?.tiporecursoA?.label}</Text> */}
                                                <Text style={this.styles.fontText(180, false, 250, this.fontSizeA(complemento?.entityR?.label))}>{complemento?.entityR?.label}</Text>
                                                <Text style={this.styles.fontText(288, false, 250, 7)}>{dateSplitted[2]}</Text>
                                                <Text style={this.styles.fontText(302, false, 250, 7)}>{dateSplitted[1]}</Text>
                                                <Text style={this.styles.fontText(317, false, 250, 7)}>{dateSplitted[0]}</Text>
                                                {
                                                    moneyFormatted.map((money, i) => {
                                                        var j = 0;
                                                        if (i > 5) {
                                                            j = 25;
                                                        } else if (i > 2) { j = 15; }
                                                        return <Text style={this.styles.fontText(false, 72 + j + (i * 15), 250, 7)}>{moneyFormatted[moneyFormatted.length - 1 - i]}</Text>

                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    if (complemento.tiporecursoR.value === "4") {
                                        return (
                                            <React.Fragment>
                                                {/* <Text style={this.styles.fontText(20, false, 164, 7)}>{ahorro?.tiporecursoA?.label}</Text> */}
                                                <Text style={this.styles.fontText(180, false, 260, this.fontSizeA(complemento?.entityR?.label))}>{complemento?.entityR?.label}</Text>
                                                <Text style={this.styles.fontText(288, false, 260, 7)}>{dateSplitted[2]}</Text>
                                                <Text style={this.styles.fontText(302, false, 260, 7)}>{dateSplitted[1]}</Text>
                                                <Text style={this.styles.fontText(317, false, 260, 7)}>{dateSplitted[0]}</Text>
                                                {
                                                    moneyFormatted.map((money, i) => {
                                                        var j = 0;
                                                        if (i > 5) {
                                                            j = 25;
                                                        } else if (i > 2) { j = 15; }
                                                        return <Text style={this.styles.fontText(false, 72 + j + (i * 15), 260, 7)}>{moneyFormatted[moneyFormatted.length - 1 - i]}</Text>

                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    if (complemento.tiporecursoR.value === "5") {
                                        return (
                                            <React.Fragment>
                                                <Text style={this.styles.fontText(108, false, 270, 5)}>{complemento?.tiporecursoR?.label}</Text>
                                                <Text style={this.styles.fontText(180, false, 270, this.fontSizeA(complemento?.entityR?.label))}>{complemento?.entityR?.label}</Text>
                                                <Text style={this.styles.fontText(288, false, 270, 7)}>{dateSplitted[2]}</Text>
                                                <Text style={this.styles.fontText(302, false, 270, 7)}>{dateSplitted[1]}</Text>
                                                <Text style={this.styles.fontText(317, false, 270, 7)}>{dateSplitted[0]}</Text>
                                                {
                                                    moneyFormatted.map((money, i) => {
                                                        var j = 0;
                                                        if (i > 5) {
                                                            j = 25;
                                                        } else if (i > 2) { j = 15; }
                                                        return <Text style={this.styles.fontText(false, 72 + j + (i * 15), 270, 7)}>{moneyFormatted[moneyFormatted.length - 1 - i]}</Text>

                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    }

                                    // return (
                                    //     <React.Fragment>
                                    //         <Text style={this.styles.fontText(20, false, valuerow, 7)}>{complemento?.tiporecursoR?.label}</Text>
                                    //         {
                                    //             valuer?.map((comp, i) => {
                                    //                 return <Text style={this.styles.fontText(false, 73 + (i * 15), valuerow, 7)}>{valuer[valuer.length - 1 - i]}</Text>
                                    //             })
                                    //         }
                                    //     </React.Fragment>
                                    // )
                                })
                            }

                            {
                                complementoSplitted?.map((previo, i) => {
                                    var j = 0;
                                    if (i > 5) {
                                        j = 25;
                                    } else if (i > 2) { j = 15; }
                                    return <Text style={this.styles.fontText(false, 72 + j + (i * 15), 280, 7)}>{complementoSplitted[complementoSplitted.length - 1 - i]}</Text>
                                })
                            }

                            {
                                totalahorropreviosplit?.map((total, i) => {
                                    var j = 0;
                                    if (i > 5) {
                                        j = 25;
                                    } else if (i > 2) { j = 15; }
                                    return <Text style={this.styles.fontText(false, 72 + j + (i * 15), 297, 7)}>{totalahorropreviosplit[totalahorropreviosplit.length - 1 - i]}</Text>
                                })
                            }

                            {
                                totalrecursoscomplementariossplit?.map((total, i) => {
                                    var j = 0;
                                    if (i > 5) {
                                        j = 25;
                                    } else if (i > 2) { j = 15; }
                                    return <Text style={this.styles.fontText(false, 73 + j + (i * 15), 307, 7)}>{totalrecursoscomplementariossplit[totalrecursoscomplementariossplit.length - 1 - i]}</Text>
                                })
                            }

                            {
                                valorsubsidiosolicitadosplit?.map((total, i) => {
                                    var j = 0;
                                    if (i > 5) {
                                        j = 25;
                                    } else if (i > 2) { j = 15; }
                                    return <Text style={this.styles.fontText(false, 73 + j + (i * 15), 315, 7)}>{valorsubsidiosolicitadosplit[valorsubsidiosolicitadosplit.length - 1 - i]}</Text>
                                })
                            }
                            {
                                cierresplit?.map((total, i) => {
                                    var j = 0;
                                    if (i > 5) {
                                        j = 25;
                                    } else if (i > 2) { j = 15; }
                                    return <Text style={this.styles.fontText(false, 73 + j + (i * 15), 325, 7)}>{cierresplit[cierresplit.length - 1 - i]}</Text>
                                })
                            }
                            {
                                valorviviendaadquirirsplit?.map((total, i) => {
                                    var j = 0;
                                    if (i > 5) {
                                        j = 25;
                                    } else if (i > 2) { j = 15; }
                                    return <Text style={this.styles.fontText(false, 73 + j + (i * 15), 334, 7)}>{valorviviendaadquirirsplit[valorviviendaadquirirsplit.length - 1 - i]}</Text>
                                })
                            }
                            {
                                presupuestosplit?.map((total, i) => {
                                    var j = 0;
                                    if (i > 5) {
                                        j = 25;
                                    } else if (i > 2) { j = 15; }
                                    return <Text style={this.styles.fontText(false, 73 + j + (i * 15), 352, 7)}>{presupuestosplit[presupuestosplit.length - 1 - i]}</Text>
                                })
                            }
                            {
                                valorSFVsplit?.map((total, i) => {
                                    var j = 0;
                                    // if (i > 5) {
                                    //     j = 25;
                                    // } else if (i > 2) { j = 15; }
                                    return <Text style={this.styles.fontText(false, 270 + j + (i * 15), 315, 7)}>{valorSFVsplit[valorSFVsplit.length - 1 - i]}</Text>
                                })
                            }
                            {
                                avaluocatastralsplit?.map((total, i) => {
                                    var j = 0;
                                    if (i > 5) {
                                        j = 25;
                                    } else if (i > 2) { j = 15; }
                                    return <Text style={this.styles.fontText(false, 73 + j + (i * 15), 362, 7)}>{avaluocatastralsplit[avaluocatastralsplit.length - 1 - i]}</Text>
                                })
                            }
                            {
                                totalavaluosplit?.map((total, i) => {
                                    var j = 0;
                                    if (i > 5) {
                                        j = 25;
                                    } else if (i > 2) { j = 15; }
                                    return <Text style={this.styles.fontText(false, 73 + j + (i * 15), 372, 7)}>{totalavaluosplit[totalavaluosplit.length - 1 - i]}</Text>
                                })
                            }
                            <Image style={this.styles.imagePosition(40, false, 485, '200px', '47px')} src={firmaPostu} />
                            <Text style={this.styles.fontText(102, false, 536, this.fontSize(namePostulante))}>{namePostulante}</Text>
                            <Text style={this.styles.fontText(64, false, 549, 7)}>{datospostulante?.numberId}</Text>

                            <Text style={this.styles.fontText(143, false, 614, this.fontSize(nameConyugue))}>{nameConyugue}</Text>
                            <Image style={this.styles.imagePosition(40, false, 568, '200px', '44px')} src={srcFirmaConyugue} />
                            <Text style={this.styles.fontText(64, false, 629, 7)}>{idConyugue}</Text>

                        </View>
                    </Page>
                </Document>
            </React.Fragment>
        );
    }
}
