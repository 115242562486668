import {
    UPDATE_FIELD_ATTRIBUTE,
    SUBMIT_SUCCESS,
    SIGN_USER,
    LOG_USER,
    AUTH_SUCCESS,
    AUTH_ERROR,  
    AUTH_LOGIN_SUCCESS,
    SELECT_FORM_FIELD,
    SAVE_ERASER_SUCCESS,
    UPDATE_COMPANY_FIELD_ATTRIBUTE,
    LOAD_CATALOG_SUCCESS,
    ADD_RECURSO_AHORRO,
    FORM_INTIALIZED,
    SAVE_FINAL_SUCCESS,
    LOAD_DOCUMENT_SUCCESS,
    CHARGED_CATALOG,
    CARGAR_CATALOGOS,
    UPDATE_INGRE_STEP2,
    UPDATE_DELETE_STEP2,
    SAVE_DATA_EXT,
    UPDATE_TOTAL_STEP2,
    UPDATE_INGRE_STEP4,
    UPDATE_DELETE_STEP4,
    UPDATE_TOTAL_STEP4,
    CALL_DINAMIC_DISABLE,
    HIDDEN_DISABLE,
    CHARGED_VALIDATOR,
    ACTIVATE_RADIO,
    HIDDEN_CLOSE,
    OPTIONS_TABLE,
    DELETE_RECURSO_TABLE,
    CUENTAS_CREDITO,
    BASE64_ADD,
    BASE64_REMOVE,
    CONTACTO_ADD,
    RELOAD_PAGE,
    SAVE_REFFILES,
    GENERATE_PDF,
    RENDER_PDF,
    ANEXOS_STORAGE,
    INIT_VALIDATE,
    DELETE_ANEXO,
    REFERENCE_FILES,
    INCREASE_SIGUIENTE,
    DATA_EMP_SUBSIDIO,
    SAVE_REFFIRMAS,
    GUARDAR_ITEMS,
    LIMPIAR_ADJUNTOS,
    INCREMENT_DECREMENT,
    ENABLED_DISABLED_ARRAY,
    REBOOT_FORM,
    SAVE_CATALOGO_CICLOS,
    CHANGE_STEP,
    INTEGRANTES_OBLIGATION,
    SAVE_TO_UPLOAD_FILE,
    STEP3_COMPLETO,
    OPEN_MODAL_ESPERA,
    SET_FIREBASE_USER_SESSION,
    SAVE_USER_COLABORADOR,
    ENABLE_ANEXOS_RESOURCES,
    ACTIONS_TABLE_ANEXOS_RESORSES,
    CHANGE_UPDATE_UPLOAD_FILE,
    FORM_FIRMA_SUCCESS,
    UPDATE_DYNAMIC_DISABLED,
    SAVE_INTEGRANTES_OBLIGATORIOS,
    OPEN_MODAL_MESSAGE_CLOSE_FORM,
    CLEAN_UPLOAD_FIELDS,
    MODAL_MESSAGE_ALERT,
    SAVE_SALARIO_MIN,
} from './constants'

export function updateAttributes (payload) {
    return {
        type: UPDATE_FIELD_ATTRIBUTE,
        payload
    };
}

export function submitSuccess (payload) {
    return {
        type: SUBMIT_SUCCESS,
        payload
    };
}

export function increaseStep (payload) {
    return {
        type: UPDATE_COMPANY_FIELD_ATTRIBUTE,
        payload
    };
}

export function saveEraserSuccess (payload) {
    return {
        type: SAVE_ERASER_SUCCESS,
        payload
    };
}

export function loadCatalogSuccess (payload) {
    return {
        type: LOAD_CATALOG_SUCCESS,
        payload
    };
}

export function saveFinalSuccess (payload) {
    return {
        type: SAVE_FINAL_SUCCESS,
        payload
    };
}

export function formInitialized (payload) {
    return {
        type: FORM_INTIALIZED,
        payload
    };
}

export function saveUserColaborador (payload) {
    return {
        type: SAVE_USER_COLABORADOR,
        payload
    };
}

export function enableAnexosResources (payload) {
    return {
        type: ENABLE_ANEXOS_RESOURCES,
        payload
    };
}

export function actionTableAnexosResources (payload) {
    return {
        type: ACTIONS_TABLE_ANEXOS_RESORSES,
        payload
    };
}

export function changeUpdateUploadFile (payload) {
    return {
        type: CHANGE_UPDATE_UPLOAD_FILE,
        payload
    };
}

export function loadDocumentSuccess (payload) {
    return {
        type: LOAD_DOCUMENT_SUCCESS,
        payload
    };
}
export function chargedCatalog (payload) {
    return {
        type: CHARGED_CATALOG,
        payload
    };
    
}

export function cargarCatalogos (payload) {
    return {
        type: CARGAR_CATALOGOS,
        payload
    };
}

export function updateIngreStep2 (payload) {
    return {
        type: UPDATE_INGRE_STEP2,
        payload
    };
}

export function updateDeleteStep2 (payload) {
    return {
        type: UPDATE_DELETE_STEP2,
        payload
    };
}

export function updateTotalStep2 (payload) {
    return {
        type: UPDATE_TOTAL_STEP2,
        payload
    };
}

export function updateIngreStep4 (payload) {
    return {
        type: UPDATE_INGRE_STEP4,
        payload
    };
}

export function updateDeleteStep4 (payload) {
    return {
        type: UPDATE_DELETE_STEP4,
        payload
    };
}

export function updateTotalStep4 (payload) {
    return {
        type: UPDATE_TOTAL_STEP4,
        payload
    };
}

export function callDinamicDisable (payload) {
    return {
        type: CALL_DINAMIC_DISABLE,
        payload
    };
}

export function hiddenDisable (payload) {
    return {
        type: HIDDEN_DISABLE,
        payload
    };
}

export function chargedValidator (payload) {
    return {
        type: CHARGED_VALIDATOR,
        payload
    };
}

export function activateRadio(payload) {
    return {
        type: ACTIVATE_RADIO,
        payload
    };
}

export function addRecursoAhorro(payload) {
    return {
        type: ADD_RECURSO_AHORRO,
        payload
    };
}

export function cleanUploadFields(payload) {
    return {
        type: CLEAN_UPLOAD_FIELDS,
        payload
    };
}

export function hiddenClose(payload) {
    return {
        type: HIDDEN_CLOSE,
        payload
    };
}

export function optionsTable(payload) {
    return {
        type: OPTIONS_TABLE,
        payload
    };
}

export function deleteRecursoTable(payload) {
    return {
        type: DELETE_RECURSO_TABLE,
        payload
    };
}


export function base64Remove(payload) {
    return {
        type: BASE64_REMOVE,
        payload
    };
}

export function base64Add(payload) {
    return {
        type: BASE64_ADD,
        payload
    };
}

export function openModalMessageCloseForm(payload) {
    return {
        type: OPEN_MODAL_MESSAGE_CLOSE_FORM,
        payload
    };
}

export function contactoAdd (payload) {
    return {
        type: CONTACTO_ADD,
        payload
    };
}
export function reloadPage (payload) {
    return {
        type: RELOAD_PAGE,
        payload
    };
}

export function updateDynamicDisabled (payload) {
    return {
        type: UPDATE_DYNAMIC_DISABLED,
        payload
    };
}

export function saveRefFiles (payload) {
    return {
        type: SAVE_REFFILES,
        payload
    };
}
export function saveDataExt (payload) {
    return {
        type: SAVE_DATA_EXT,
        payload
    };
}

export function generatePdf (payload) {
    return {
        type: GENERATE_PDF,
        payload
    };
}
export function renderPdf (payload) {
    return {
        type: RENDER_PDF,
        payload
    };
}

export function anexosStorage (payload) {
    return {
        type: ANEXOS_STORAGE,
        payload
    };
}

export function saveSalarioMin (payload) {
    return {
        type: SAVE_SALARIO_MIN,
        payload
    };
}

export function formFirmaSuccess (payload) {
    return {
        type: FORM_FIRMA_SUCCESS,
        payload
    };
}
export function dataEmpSubsidio (payload) {
    return {
        type: DATA_EMP_SUBSIDIO,
        payload
    };
}

export function initValidate (payload) {
    return {
        type: INIT_VALIDATE,
        payload
    };
}

export function deleteAnexo (payload) {
    return {
        type: DELETE_ANEXO,
        payload
    };
}

export function referenceFiles (payload) {
    return {
        type: REFERENCE_FILES,
        payload
    };
}

export function saveRefirmas (payload) {
    return {
        type: SAVE_REFFIRMAS,
        payload
    };
}

export function increaseSiguiente (payload) {
    return {
        type: INCREASE_SIGUIENTE,
        payload
    };
}

export function setFirebaseUserSession (payload) {
    return {
        type: SET_FIREBASE_USER_SESSION,
        payload
    };
}

export function step3Completo (payload) {
    return {
        type: STEP3_COMPLETO,
        payload
    };
}

export function modalMessageAlert (payload) {
    return {
        type: MODAL_MESSAGE_ALERT,
        payload
    };
}

export function guardarItems (payload) {
    return {
        type: GUARDAR_ITEMS,
        payload
    };
}


export function limpiarAdjuntos (payload) {
    return {
        type: LIMPIAR_ADJUNTOS,
        payload
    };
}

export function incrementDecrement (payload) {
    return {
        type: INCREMENT_DECREMENT,
        payload
    };
}

export function enabledDisabledArray (payload) {
    return {
        type: ENABLED_DISABLED_ARRAY,
        payload
    };
}

export function rebootForm (payload) {
    return {
        type: REBOOT_FORM,
        payload
    };
}

export function saveCatalogoCiclos (payload) {
    return {
        type: SAVE_CATALOGO_CICLOS,
        payload
    };
}

export function changeStep (payload) {
    return {
        type: CHANGE_STEP,
        payload
    };
}

export function integrantesObligation (payload) {
    return {
        type: INTEGRANTES_OBLIGATION,
        payload
    };
}

export function saveIntegrantesObligatorios (payload) {
    return {
        type: SAVE_INTEGRANTES_OBLIGATORIOS,
        payload
    };
}

export function saveToUploadFile (payload) {
    return {
        type: SAVE_TO_UPLOAD_FILE,
        payload
    };
}

export function openModalEspera (payload) {
    return {
        type: OPEN_MODAL_ESPERA,
        payload
    };
}