import React, { Component, useRef } from "react";
import { clearFields, Field, FieldArray, FormSection } from "redux-form";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DescriptionIcon from '@material-ui/icons/Description';
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Button, ButtonGroup, Switch, Input } from "@material-ui/core";
import { FileUpload } from "primereact/fileupload";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import InputMask from 'react-input-mask';
import { SelectButton } from 'primereact/selectbutton';
// import Autocomplete from '@mui/material/Autocomplete';
import { AutoComplete } from "primereact/autocomplete";

import "./form.css";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import { Rating } from "primereact/rating";

import NumberFormat from 'react-number-format';

import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from 'primereact/inputnumber';

import { TRATAMIENTO_DATOS, TRATAMIENTO_DATOSFinal } from "./contants";
import { getDynamicDisable } from "../../pages/contactos/selectors";
import { Label } from "recharts";

import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';

import ReCAPTCHA from "react-google-recaptcha";
import { MyComponentCapcha, TextInputCharacter } from "./componentesPropios";
import { withStyles, makeStyles } from '@material-ui/core/styles';


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#000',
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",//theme.shadows[1],
    fontSize: 11,
    border: "2px solid #41c40d",
    'font-weight': 'bold',
  },
}))(Tooltip);

export default class MaterialUiForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      captcha: false,
      countries: [],
      selectedCountry1: "",
      filteredCountries: null,
      filteredItems: null,
      area: ""
    }
    this.onChangeArea = this.onChangeArea.bind(this);
    this.searchCountry = this.searchCountry.bind(this);
  }

  renderTextJura = ({
    label,
    input,
    placeholder,
    meta: { touched, invalid, error },
    ...custom
  }) => {


    return (
      <div className={"card-content-jura"}>
        <Panel >
          {custom.options.length > 0 ?
            custom.options.map(item => {
              return (<p>{item}</p>);
            })
            : ""}
        </Panel>
      </div>
    )
  };



  renderCardText = ({
    label,
    input,
    placeholder,
    meta: { touched, invalid, error },
    ...custom
  }) => {

    return (
      <div className={"card-content-anexo"}>
        {/* <h5>{placeholder}</h5> */}
        <Panel header={placeholder} toggleable collapsed>
          {custom.options.length > 0 ?
            custom.options.map(item => {
              return (<p>{item}</p>);
            })
            : ""}
        </Panel>
      </div>
    )
  };

  searchCountry = (countries, event) => {

    setTimeout(() => {
      let filteredCountries;
      if (!event.query.trim().length) {
        filteredCountries = [...countries];
      } else {
        filteredCountries = countries.filter((country) => {
          return country.label
            .toLowerCase()
            .includes(event.query.toLowerCase());
        });

      }

      this.setState({ filteredCountries });
    }, 50);
  }

  renderTypeNumber = ({
    label,
    input,
    placeholder,
    meta: { touched, invalid, error },
    ...custom
  }) => (
    <FormControl error={touched && !!error}>
      <div>
        <NumberFormat
          prefix="$ "
          customInput={TextField}
          thousandSeparator={true}
          inputMode="numeric"
          id={input.name}
          value={input.value}
          inputProps={{ maxLength: "250" }}
          disabled={custom.disabled}
          error={error ? true : false}
          label={placeholder}
          name={input.name}
          {...input}
        //{...custom}
        />
        {this.renderFromHelper({ touched, error })}
      </div>



      <div></div>
    </FormControl>

  );


  onChangeArea = (e) => {

    if (e.target && e.target.value.length > 0) {
      const newValue = e.target.value.replace(/[^\w\s]/gi, '');
      this.setState({ area: newValue });// only set when successful
    }

  };

  renderTextArea = ({
    label,
    input,
    placeholder,
    meta: { touched, invalid, error },
    ...custom
  }) => (
    <FormControl error={touched && !!error}>

      <InputTextarea
        {...input}
        {...custom}
        autoResize
        id={input.name}
        disabled={custom.disabled}
        placeholder={placeholder}
        value={this.state.area}
        onChange={this.onChangeArea}
      />
      {this.renderFromHelper({ touched, error })}
      <div></div>
    </FormControl>

  );

  renderInputLabel = ({
    label,
    input,
    placeholder,
    meta: { touched, invalid, error },
    ...custom
  }) => (
    <div className="labelIns">

      <InputLabel className="labelInstruccion">
        {custom?.options?.length > 0 ? <p className="labelNor"> <p className="labelNeg"> {custom.options[0]}</p> {placeholder} </p> : placeholder}</InputLabel>
    </div>
  );

  renderInputLabel2 = ({
    label,
    input,
    placeholder,
    meta: { touched, invalid, error },
    ...custom
  }) => (
    <div className="labelIns">

      <InputLabel className="labelInstruccion">
        {
          custom.options.length > 0 ?
            custom.options.map(item => {
              return (<p>{item}</p>);
            })
            : ""
        }
      </InputLabel>
    </div>
  );



  renderTextField = ({
    label,
    input,
    placeholder,
    meta: { touched, invalid, error },
    ...custom
  }) => (
    <div>
      <TextField
        label={placeholder}
        id={input.name}
        disabled={custom.disabled}
        error={error ? true : false}
        inputProps={input.name === "datospostulante.companyPhone" ? { maxLength: "32" } : { maxLength: "84" }}
        //helpertext={touched && error}
        placeholder={placeholder}
        {...input}
        {...custom}
      />
      {this.renderFromHelper({ touched, error })}
    </div>
  );


  renderTextInputCharacter = ({
    label,
    input,
    placeholder,
    meta: { touched, invalid, error },
    ...custom
  }) => (
    <React.Fragment>
      <TextInputCharacter
        placeholder={placeholder}
        name={input.name}
        {...input}
        {...custom}
        touched={touched}
        error={error}
      />

      {this.renderFromHelper({ touched, error })}
    </React.Fragment>
  );

  getSelectedOption = (event) => {
    if (event) {
      this.setState({ captcha: true })
    }
  }


  renderCaptchaGoogle = ({
    label,
    input,
    placeholder,
    meta: { touched, invalid, error },
    ...custom
  }) => (
    <React.Fragment>
      <div className="recaptcha">

        <ReCAPTCHA
          id={input.name}
          disabled={custom.disabled}
          sitekey={process.env.REACT_APP_KEY_TO_RECAPTCHA_GOOGLE}
          onChange={(e) => this.getSelectedOption(e)}
          error={error ? true : false}
          value={this.state.captcha}
          {...input}
        // {...custom}
        />
        {/* <MyComponentCapcha
          id={input.name}
          disabled={custom.disabled}
          sitekey={"6LfRwNcmAAAAAH_pte7rRjgL_sKpiC_t75lJ3vTw"}//{process.env.REACT_APP_KEY_TO_RECAPTCHA_GOOGLE}
          onChange={(e) => this.getSelectedOption(e)}
          error={error ? true : false}
          value={this.state.captcha}
          {...input}
        /> */}
      </div>
      {this.renderFromHelper({ touched, error })}
    </React.Fragment>
  );


  renderSrc = ({ input, meta: { touched, error, invalid }, label }) => (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={input.value ? true : false}
            onChange={input.onChange}
          />
        }
      />
      {this.renderFromHelper({ touched, error })}
    </div>
  );
  renderLinkGe = ({ input, meta: { touched, error, invalid }, label, ...custom }) => (
    <div>
      <div className="checkHabeas">
        <FormControlLabel
          control={
            <Checkbox
              checked={input.value ? true : false}
              onChange={input.onChange}
              disabled={custom.disabled}
            />
          }
          label={
            <p className="datosAutorizo">
              {custom.placeholder}
              <Link
                // color=""
                onClick={() => {
                  window.open(
                    "https://www.comfenalcovalle.com.co/corporativo/quienes-somos/politica-de-tratamiento-de-la-informacion"
                  );
                }}
              >
                {custom.options[0].length > 0 ? custom.options[0] : ""}
              </Link>
              {"*"}
            </p>
          }
        />
      </div>
      {this.renderFromHelper({ touched, error })}
    </div>
  );
  renderLink = ({ input, meta: { touched, error, invalid }, label, ...custom }) => (
    <div>
      <div className="checkHabeas">
        <FormControlLabel
          control={
            <Checkbox
              checked={input.value ? true : false}
              onChange={input.onChange}
              disabled={custom.disabled}
            />
          }
          label={
            <p className="datosAutorizo">
              {TRATAMIENTO_DATOS}
              <Link
                // color=""
                onClick={() => {
                  window.open(
                    "https://www.comfenalcovalle.com.co/corporativo/quienes-somos/politica-de-tratamiento-de-la-informacion"
                  );
                }}
              >
                {" "}
                política de tratamiento{" "}
              </Link>
              {TRATAMIENTO_DATOSFinal}
            </p>
          }
        />
      </div>
      {this.renderFromHelper({ touched, error })}
    </div>
  );
  renderCheckbox = ({ input, meta: { touched, error, invalid }, label, placeholder }) => (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={input.value ? true : false}
            onChange={input.onChange}
          />
        }
        label={placeholder}
      />
      {this.renderFromHelper({ touched, error })}
    </div>
  );

  radioButton = ({ input, meta: { touched, error, invalid }, children, ...rest }) => {
    const { options, key } = rest;

    return (
      <FormControl >
        <RadioGroup {...children} {...input} {...rest}>
          {options.map((item, ri) => {
            return (
              <FormControlLabel
                //key={`ri-${ri}`}
                value={item.value}
                control={<Radio />}
                label={item.label}
                checked={item.value === item.handleRadio ? true : false}
              />
            );
          })}
        </RadioGroup>
        {this.renderFromHelper({ touched, error })}
      </FormControl>
    );
  };
  radioButton2 = ({ input, meta: { touched, error, invalid }, children, ...rest }) => {
    const { options, key } = rest;

    return (
      <FormControl >
        <RadioGroup {...children} {...input} {...rest}>
          {options.map((item, ri) => {
            return (
              <FormControlLabel
                //key={`ri-${ri}`}
                value={item.value}
                control={<Radio />}
                label={item.label}
              //checked={item.value}
              />
            );
          })}
        </RadioGroup>
        {this.renderFromHelper({ touched, error })}
      </FormControl>
    );
  };

  renderFromHelper = ({ touched, error }) => {
    if (!(error)) {
      return;
    } else {
      return <FormHelperText>{true && error}</FormHelperText>;
    }
  };

  stateInput = (value) => {
    var sta = ""
    if (value) {
      sta = value;
    }
    return sta;
  }

  renderStarsButton = ({
    input,
    label,
    meta: { touched, error, invalid },
    children,
    ...custom
  }) => (
    <Rating value={1} cancel={false} error={touched && invalid} {...input} />
  );

  renderSwitchButton = ({
    input,
    label,
    meta: { touched, error, invalid },
    children,
    ...custom
  }) => <Switch cancel="medium" error={touched && invalid} {...input} />;

  // renderAutocomplete = ({
  //   input,
  //   label,
  //   meta: { touched, error, invalid },
  //   children,
  //   ...custom

  // }) => (

  //   <FormControl error={touched && !!error}>
  //     <InputLabel htmlFor={`${input.name}-native-simple`}>{ }</InputLabel>
  //     <Autocomplete
  //       className={input.className}
  //       placeholder={label}
  //       name={input.name}
  //       id={input.name}
  //       clearOnBlur
  //       handleHomeEndKeys
  //       selectOnFocus
  //       disabled={custom.disabled}
  //       options={custom.options}
  //       getOptionLabel={(option) => option.label}
  //       renderInput={(params) => (
  //         <TextField
  //           name={input.name}
  //           {...params}
  //           label={label}
  //           error={error && invalid ? true : false}
  //           placeholder={label}
  //           {...input}
  //         />
  //       )}
  //     />
  //     {this.renderFromHelper({ touched, error })}
  //   </FormControl>
  // );

  renderAutocomplete = ({
    input,
    label,
    placeholder,
    meta: { touched, error, invalid },
    children,
    ...custom

  }) => {

    return (
      <React.Fragment>
        <AutoComplete
          value={this.state.selectedCountry1}
          suggestions={this.state.filteredCountries}
          completeMethod={(e) => this.searchCountry(custom.options, e)}
          field="label"
          onChange={(e) => this.setState({ selectedCountry1: e.value })}
          dropdown
          placeholder={placeholder}
          name={input.name}
          {...input}
          {...custom}
          touched={touched}
          error={error}
        />


        {this.renderFromHelper({ touched, error })}
      </React.Fragment>

    )
  };

  renderSelectField = ({
    input,
    label,
    meta: { touched, error },
    children,
    ...custom
  }) => (
    <FormControl error={touched && !!error}>
      <InputLabel htmlFor={`${input.name}-native-simple`}>
        {custom.placeholder}
      </InputLabel>

      <Select
        native
        error={error ? true : false}
        inputProps={{
          name: input.name,
          id: `${input.name}-native-simple`,
          disabled: custom.disabled,
        }}
        {...input}
        {...custom}
      >
        {<option key={`option-${input.name}`} value=""></option>}
        {custom.options.map((item, k) => {
          return (
            <option key={`s-option-${k}`} value={item.value}>
              {item.label}
            </option>
          );
        })}
      </Select>
      {/* //</div> */}
      {this.renderFromHelper({ touched, error })}
    </FormControl>
  );

  renderSpacer = (customKey) => (
    <div className={"custom-input spacer"} key={customKey} />
  );

  renderChidlren = ({ fields, meta: { touched, error }, ...props }) => (
    <ul className="form-child">
      {/* {props.label && <h3>{"Integrantes posulados"}</h3>} */}
      {fields &&
        fields.length > 0 &&
        fields.map((member, index) => (

          <li className={`${member}`} key={index}>
            <h4>
              {props.fieldtitle} #{index + 1}
            </h4>
            <MaterialUiForm
              submitable={false}
              items={props.subitems}
              className="field-child"
              member={member}
              handleSuma={props.handleSuma}
              enabledAnexos={props.enabledAnexos}
              firmaLabel={props.firmaLabel}
              updateUploadFile={props.updateUploadFile}
              actionTableAnexosResources={props.actionTableAnexosResources}
              dynamicOptions={props.dynamicOptions}
              optionsInthogar={props.optionsInthogar}
              validations={props.validations}
              deleteRecurso={props.deleteRecurso}
              rebootFiles={props.rebootFiles}
              anexosStorage={props.anexosStorage}
              dynamicDisable={props.dynamicDisable}
              formName={props.formName}
              addInthogar={props.addInthogar}
              invalid={props.invalid}
              hiddenDisable={props.hiddenDisable}
              deleteAnexo={props.deleteAnexo}
              deleteFirma={props.deleteFirma}
              hiddenClose={props.hiddenClose}
              activateRadio={props.activateRadio}
              handleUpload={props.handleUpload}
              handleRemove={props.handleRemove}
              handleUploadF={props.handleUploadF}
              handleRemoveF={props.handleRemoveF}
              charged={props.charged}
              enableDisableArray={props.enableDisableArray}
            />

            <div className={`button-wrapper delete `}>
              <div>
              </div>
              <ButtonGroup color="primary" aria-label="primary button group">
                <Button
                  color="secondary"
                  type="button"
                  className="delete"
                  //disabled={props.enableDisableArray? true: false}
                  title={props.removeLabel}
                  onClick={() => {
                    props.handleSuma({ member, index });
                    if (props.enableDisableArray === false || props.enableDisableArray < index) fields.remove(index);
                  }}
                >
                  {props.removeLabel}
                </Button>
              </ButtonGroup>
            </div>
          </li>
        ))}

      <li>
        {props.label ?
          <div className="labelIns">
            <InputLabel className="labelInstruccion"> {props.label}</InputLabel>
          </div>
          : ""}
        <div className="button-wrapper add">
          <ButtonGroup color="primary" aria-label="primary button group">
            <Button
              color="primary"
              type="button"
              className="add"
              title={props.addLabel}
              onClick={fields?.length < 50 ? () => fields.push({}) : ""}
            >
              {props.addLabel}
            </Button>
          </ButtonGroup>
        </div>
        {touched && error && <span>{error}</span>}
      </li>
    </ul>
  );

  render() {
    const {
      items,
      member,
      sectionTitle,
      handleUpload,
      handleRemove,
      handleSuma,
      enabledAnexos,
      firmaLabel,
      updateUploadFile,
      actionTableAnexosResources,
      enableDisableArray,
      handleRemoveF,
      handleUploadF,
      Hclear,
      invalid,
      validations,
      dynamicOptions,
      optionsInthogar,
      hiddenClose,
      dynamicDisable,
      hiddenDisable,
      deleteAnexo,
      deleteFirma,
      activateRadio,
      optionsTable,
      anexosStorage,
      deleteRecurso,
      rebootFiles,
      addInthogar,
      charged,
      formName,
    } = this.props;


    return (
      <React.Fragment>
        <div className="field-container">
          {sectionTitle && <h3>{sectionTitle}</h3>}

          {items.map((field, j) => {
            let renderComponent = {};
            const specialName = member ? `${member}.${field.name}` : field.name;
            let fileData = false;
            if (field.typeForm === "file" || field.typeForm === "fileF" || field.typeForm === "fileA" || field.typeForm === "fileDinamic") {
              fileData = {
                name: specialName,
                label: field.label,
                formName,
              };
            }



            field.hidden =
              hiddenDisable && hiddenDisable[specialName] !== undefined
                ? hiddenDisable[specialName]
                : field.hidden;


            field.options =
              optionsTable && optionsTable[field.name]
                ? optionsTable[field.name]
                : field.options;

            field.options =
              dynamicOptions && dynamicOptions[field.name]
                ? dynamicOptions[field.name]
                : field.options;

            field.options =
              optionsInthogar && optionsInthogar[specialName]
                ? optionsInthogar[specialName]
                : field.options;

            if (specialName && specialName.includes("inthogarpostulantes[")) {
              field.disabled =
                dynamicDisable && dynamicDisable[specialName] !== undefined && dynamicDisable[specialName] === true
                  ? true
                  : false;
            } else {
              field.disabled =
                dynamicDisable && dynamicDisable[specialName] !== undefined
                  ? dynamicDisable[specialName]
                  : field.disabled;
            }

            if (rebootFiles !== false && rebootFiles !== undefined) {
              if (rebootFiles.name === field.name) {
                field.fields = rebootFiles.fields;
              }
            }

            // field.charged = charged && charged[field.name] ? charged[field.name] : field.charged;
            switch (field.typeForm) {
              case "text":
                renderComponent = this.renderTextField;
                break;
              case "textCharacter":
                renderComponent = this.renderTextInputCharacter;
                break;
              case "captcha":
                renderComponent = this.renderCaptchaGoogle;
                break;
              case "radio":
                renderComponent = this.radioButton;
                break;
              case "radio2":
                renderComponent = this.radioButton2;
                break;
              case "checkbox":
                renderComponent = this.renderCheckbox;
                break;
              case "select":
                renderComponent = this.renderSelectField;
                break;
              case "switch":
                renderComponent = this.renderSwitchButton;
                break;
              case "spacer":
                renderComponent = this.renderSpacer;
                break;
              case "array":
                renderComponent = this.renderChidlren;
                break;
              case "stars":
                renderComponent = this.renderStarsButton;
                break;
              case "src":
                renderComponent = this.renderSrc;
                break;
              case "link":
                renderComponent = this.renderLink;
                break;
              case "link2":
                renderComponent = this.renderLinkGe;
                break;
              case "area":
                renderComponent = this.renderTextArea;
                break;
              case "label":
                renderComponent = this.renderInputLabel;
                break;
              case "label2":
                renderComponent = this.renderInputLabel2;
                break;
              case "autocomplete":
                renderComponent = this.renderAutocomplete;
                break;
              case "numberN":
                renderComponent = this.renderTypeNumber;
                break;
              case "cardText":
                renderComponent = this.renderCardText;
                break;
              case "cardJura":
                renderComponent = this.renderTextJura;
                break;

              default:
                renderComponent = this.renderTextField;
                break;
            }


            return (
              <div
                className={`material-field ${field.hidden ? "no-visible" : ""
                  } ${field.className}`}
                key={j + 'field'}
              >
                {field.typeForm === "table" ? (
                  <div>
                    <TableContainer component={Paper} className="tableEmpresa">
                      <Table
                        className={"classes.table"}
                        aria-label="simple table"
                      >
                        <TableHead >
                          <TableRow>
                            {field.rows.map((campo) => (
                              <TableCell align="right">{campo}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            {field.options.map((row) => (
                              <TableCell align="right">{row}</TableCell>
                            ))}
                          </TableRow>

                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                ) : field.typeForm === "buttonA" ? (
                  <div>
                    <div className="button-Update">
                      <p className="parrafo-Update">{field.placeholder}</p>
                      <Button
                        variant={field.variant}
                        color={"primary"}
                        type={`${field.submitable ? "submit" : "button"}`}
                        className={field.className}
                        onClick={() => window.open(field.options[0], "Popup", "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0,")}
                      >{field.label}</Button>
                    </div>
                  </div>
                ) : field.typeForm === "buttonX" ? (
                  <div>
                    <div className="button-AddRecurso">
                      <Button
                        variant={field.variant}
                        color={"primary"}
                        type={`${field.submitable ? "submit" : "button"}`}
                        className={field.className}
                        onClick={(event) => field.onClick({ field, event })}
                      //disabled={validations && field.primary ? invalid : false}
                      >{field.placeholder}</Button>
                    </div>
                  </div>
                ) : field.typeForm === "table2" ? (
                  <div>
                    <TableContainer component={Paper} className="tableAhorro">
                      <Table
                        className={"classes.table"}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            {field.rows.map((campo) => (
                              <TableCell align="right">{campo}</TableCell>
                            ))}
                            <TableCell align="right">{"    "}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {field.options.map((row) => (
                            <TableRow >
                              {row.map((campo) => (
                                <TableCell align="right">{campo.label}</TableCell>
                              ))}
                              {row.length < 5 ?
                                <TableCell align="right">{
                                  <InputLabel className="labelArchivo" name={"labelArchivo"} id={"labelArchivo"}>
                                    {"Sin archivos disponibles."}
                                  </InputLabel>
                                }</TableCell>
                                : ""
                              }
                              <TableCell align="right">
                                <ButtonGroup color="primary" aria-label="primary button group">
                                  <Button
                                    variant={field.variant}
                                    color={"secondary"}
                                    type={`${field.submitable ? "submit" : "button"}`}
                                    className={field.className}
                                    onClick={(event) => deleteRecurso({ field, event, row })}
                                  >
                                    {<DeleteForeverIcon />}
                                  </Button>
                                  {row.length > 4 ?
                                    <Button
                                      variant={field.variant}
                                      color={"secondary"}
                                      type={`${field.submitable ? "submit" : "button"}`}
                                      className={"buttonAddInth"}
                                      onClick={() => window.open(row[4].value, "_blank")}
                                    >
                                      {<VisibilityIcon />}
                                    </Button>
                                    :
                                    ""
                                  }
                                </ButtonGroup>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                ) : field.typeForm === "table3" ? (
                  <div>
                    <h3>{field.placeholder}</h3>
                    <TableContainer component={Paper} className="tableInth">
                      <Table
                        className={"classes.table"}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            {field.rows.map((campo) => (
                              <TableCell align="right">{campo}</TableCell>
                            ))}
                            <TableCell align="right">{"-----"}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {field.options.map((row) => (
                            <TableRow >
                              {row.map((campo) => (
                                <TableCell align="right">{campo.label}</TableCell>
                              ))}
                              <TableCell align="right">
                                <Button
                                  variant={field.variant}
                                  color={"secondary"}
                                  type={`${field.submitable ? "submit" : "button"}`}
                                  className={"buttonAddInth"}
                                  onClick={(event) => addInthogar({ event, row, ban: false })}
                                >
                                  {"Añadir integrante a postulación"}
                                </Button></TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                ) : field.typeForm === "array" ? (
                  <FieldArray
                    handleSuma={handleSuma}
                    // enabledAnexos={enabledAnexos}
                    firmaLabel={firmaLabel}
                    // actionTableAnexosResources={actionTableAnexosResources}
                    enableDisableArray={enableDisableArray}
                    handleUpload={handleUpload}
                    handleRemove={handleRemove}
                    handleUploadF={handleUploadF}
                    handleRemoveF={handleRemoveF}
                    validations={validations}
                    addLabel={field.addLabel}
                    removeLabel={field.removeLabel}
                    subitems={field.fields}
                    invalid={invalid}
                    name={field.name}
                    label={field.label}
                    fieldtitle={field.fieldtitle}
                    addInthogar={addInthogar}
                    component={renderComponent}
                    hiddenClose={hiddenClose}
                    activateRadio={activateRadio}
                    dynamicOptions={dynamicOptions}
                    optionsInthogar={optionsInthogar}
                    deleteRecurso={deleteRecurso}
                    rebootFiles={rebootFiles}
                    hiddenDisable={hiddenDisable}
                    deleteAnexo={deleteAnexo}
                    deleteFirma={deleteFirma}
                    formName={formName}
                    anexosStorage={anexosStorage}
                    dynamicDisable={dynamicDisable}
                    disabled={field.disabled}
                  />
                ) : field.typeForm === "section" ? (
                  <div className={`section-container ${field.hidden ? "no-visible" : ""}`}>
                    <FormSection name={field.name}>
                      <MaterialUiForm
                        sectionTitle={field.label}
                        label={<InputLabel>{field.placeholder}</InputLabel>}
                        submitable={false}
                        items={field.fields}
                        className="field-child"
                        member={member}
                        dynamicOptions={dynamicOptions}
                        optionsInthogar={optionsInthogar}
                        validations={validations}
                        deleteRecurso={deleteRecurso}
                        hiddenClose={hiddenClose}
                        rebootFiles={rebootFiles}
                        invalid={invalid}
                        anexosStorage={anexosStorage}
                        dynamicDisable={dynamicDisable}
                        hiddenDisable={hiddenDisable}
                        deleteAnexo={deleteAnexo}
                        deleteFirma={deleteFirma}
                        addInthogar={addInthogar}
                        activateRadio={activateRadio}
                        formName={formName}
                        handleUpload={handleUpload}
                        handleRemove={handleRemove}
                        handleUploadF={handleUploadF}
                        handleRemoveF={handleRemoveF}
                        handleSuma={handleSuma}
                        enabledAnexos={enabledAnexos}
                        firmaLabel={firmaLabel}
                        updateUploadFile={updateUploadFile}
                        actionTableAnexosResources={actionTableAnexosResources}
                        enableDisableArray={enableDisableArray}
                        Hclear={Hclear}
                      />
                    </FormSection>
                  </div>
                ) : field.typeForm === "file" ? (
                  <React.Fragment>
                    {field.tooltip && (
                      <LightTooltip
                        className="form-tooltip"
                        title={field.tooltip}
                        aria-label="add"
                      >
                        <HelpIcon />
                      </LightTooltip>
                    )}
                    <label className="label-name">
                      <span className="content-name">{field.label}</span>
                    </label>
                    <FormControl>
                      <FileUpload
                        name={field.name}
                        disabled={field.disabled}
                        accept=".jpg, .png, .jpeg, .pdf, .doc, .odt, .docx, .ods, .xls, .txt"
                        maxFileSize={
                          field.maxFileSize ? field.maxFileSize : 2000000
                        }
                        // onUpload={(event) =>
                        //   field.handleRemove({ event, fileData })
                        // }
                        invalidFileSizeMessageDetail={''}
                        invalidFileSizeMessageSummary={'El Tamaño del archivo supera el máximo permitido 2MB.\nRealice los ajustes correspondientes  y vuelva a intentarlo'}
                        customUpload
                        uploadHandler={(event) =>
                          field.handleUpload({ event, fileData })
                        }
                        onRemove={(event) => handleRemove({ event, fileData })}
                        chooseLabel={field.placeholder}
                        auto={field.auto}
                        multiple={field.multiple}
                        mode={field.mode ? "advanced" : "basic"}
                      />
                    </FormControl>
                  </React.Fragment>
                )
                  : field.typeForm === "fileA" ? (
                    <React.Fragment>
                      {field.tooltip && (
                        <LightTooltip
                          className="form-tooltip"
                          title={field.tooltip}
                          aria-label="add"
                        >
                          <HelpIcon />
                        </LightTooltip>
                      )}
                      <label className="label-name">
                        <span className="content-name">{field.label}</span>
                      </label>
                      <FormControl>
                        {updateUploadFile && updateUploadFile.name === field.name ?
                          <InputLabel className="labelArchivo" name={"labelArchivo"} id={"labelArchivo"}>
                            {"Cargando ..."}
                          </InputLabel>
                          :
                          <FileUpload
                            name={field.name}
                            disabled={field.disabled}
                            accept=".pdf"//.jpg, .png, .jpeg,  .doc, .odt, .docx, .ods, .xls, .txt"
                            maxFileSize={
                              field.maxFileSize ? field.maxFileSize : 2000000
                            }
                            invalidFileSizeMessageDetail={''}
                            invalidFileSizeMessageSummary={'El Tamaño del archivo supera el máximo permitido 2MB.\nRealice los ajustes correspondientes  y vuelva a intentarlo'}
                            customUpload
                            uploadHandler={(event) =>
                              field.handleUpload({ event, fileData })
                            }
                            onRemove={(event) => handleRemove({ event, fileData })}
                            chooseLabel={field.placeholder}
                            auto={field.auto}
                            multiple={field.multiple}
                            mode={field.mode ? "advanced" : "basic"}
                            onUpload={true}
                          />
                        }
                        <div className={`table-upload" ${field.options.length > 0 ? "" : "no-visible"
                          }`} >

                          <TableContainer component={Paper} className={"tableUp"}>
                            <Table
                              className={"classes.table"}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell align="right">{"Archivos cargados"}</TableCell>
                                  <TableCell align="right">{" "}</TableCell>
                                  <TableCell align="right">{" "}</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {field.options.map((row) => (

                                  <TableRow >
                                    <TableCell align="right">{row.nameArchivo}</TableCell>
                                    <TableCell align="right">
                                      <Button
                                        variant={field.variant}
                                        color={"secondary"}
                                        type={`${field.submitable ? "submit" : "button"}`}
                                        className={"buttonAddInth"}
                                        // startIcon={<GetAppIcon />}
                                        onClick={() => window.open(`https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/${row.refFile}`, "_blank")}
                                      >
                                        {<VisibilityIcon />}
                                      </Button>
                                    </TableCell>
                                    <TableCell align="right">
                                      <Button
                                        variant={field.variant}
                                        color={"secondary"}
                                        type={`${field.submitable ? "submit" : "button"}`}
                                        className={"buttonAddInth"}
                                        // startIcon={<DeleteIcon />}
                                        //href={row[1]}
                                        onClick={(event) => deleteAnexo({ event, fileData, row })}
                                      >
                                        {<DeleteForeverIcon />}
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </FormControl>
                    </React.Fragment>
                  ) : field.typeForm === "fileDinamic" ? (
                    <React.Fragment>
                      {enabledAnexos && enabledAnexos.active && enabledAnexos.type === field.name && (
                        <React.Fragment>
                          {field.tooltip && (
                            <LightTooltip
                              className="form-tooltip"
                              title={field.tooltip}
                              aria-label="add"
                            >
                              <HelpIcon />
                            </LightTooltip>
                          )}
                          <label className="label-name">
                            <span className="content-name">{`Certificado de ${enabledAnexos.placeholder}.\nLas certificaciones a anexar como soporte a la postulación deberán estar en formato PDF.  No son válidas fotos ni archivos en formato de imágenes ni documentos que requieran ingresar clave de acceso para abrirlos`}</span>
                          </label>
                          <FormControl>
                            {updateUploadFile && updateUploadFile.name === field.name ?
                              <InputLabel className="labelArchivo" name={"labelArchivo"} id={"labelArchivo"}>
                                {"Cargando ..."}
                              </InputLabel>
                              :
                              <FileUpload
                                name={field.name}
                                disabled={field.disabled}
                                accept=".pdf"//.png, .jpeg,.doc, .odt, .docx, .ods, .xls, .txt"
                                maxFileSize={
                                  field.maxFileSize ? field.maxFileSize : 2000000
                                }
                                invalidFileSizeMessageDetail={''}
                                invalidFileSizeMessageSummary={'El Tamaño del archivo supera el máximo permitido 2MB.\nRealice los ajustes correspondientes  y vuelva a intentarlo'}
                                customUpload
                                uploadHandler={(event) =>
                                  field.handleUpload({ event, fileData, type: "upload", enabledAnexos })
                                }
                                onRemove={(event) => field.handleRemove({ event, fileData, type: "remove", enabledAnexos })}
                                chooseLabel={field.placeholder}
                                auto={field.auto}
                                multiple={field.multiple}
                                mode={field.mode ? "advanced" : "basic"}

                              />
                            }
                          </FormControl>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ) : field.typeForm === "fileF" ? (
                    <React.Fragment>
                      {field.tooltip && (
                        <LightTooltip
                          className="form-tooltip"
                          title={field.tooltip}
                          aria-label="add"
                        >
                          <HelpIcon />
                        </LightTooltip>
                      )}
                      <label className="label-name">
                        <span className="content-name">{field.label}</span>
                      </label>

                      <React.Fragment>
                        {firmaLabel && firmaLabel[specialName] && firmaLabel[specialName] !== false ?

                          <div className="divArchivo">

                            <InputLabel className="labelArchivo" name={specialName} id={specialName}>
                              <DescriptionIcon />
                              {firmaLabel[specialName].name}
                            </InputLabel>
                            <ButtonGroup color="primary" aria-label="primary button group">
                              <Button
                                variant={field.variant}
                                color={"secondary"}
                                type={`${field.submitable ? "submit" : "button"}`}
                                className={"buttonFile-see"}
                                onClick={() => window.open(`https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/${firmaLabel[specialName].refFile}`, "_blank")}
                              >
                                {<VisibilityIcon />}
                              </Button>
                              <Button
                                variant={field.variant}
                                color={"secondary"}
                                type={`${field.submitable ? "submit" : "button"}`}
                                className={"buttonFile-delete"}
                                onClick={(event) => field.handleRemoveF({ event, fileData, name: specialName })}
                              >
                                {<DeleteIcon />}
                              </Button>

                            </ButtonGroup>
                          </div>
                          :
                          <FormControl>
                            <FileUpload
                              name={specialName}
                              disabled={field.disabled ? true : false}
                              accept=".jpg, .png, .jpeg"
                              maxFileSize={
                                field.maxFileSize ? field.maxFileSize : 2000000
                              }
                              onUpload={(event) =>
                                field.handleRemoveF({ event, fileData, name: specialName })
                              }
                              invalidFileSizeMessageDetail={''}
                              invalidFileSizeMessageSummary={'El Tamaño del archivo supera el máximo permitido 2MB.\nRealice los ajustes correspondientes  y vuelva a intentarlo'}

                              customUpload
                              uploadHandler={(event) =>
                                field.handleUploadF({ event, fileData, name: specialName })
                              }
                              onRemove={(event) => field.handleRemoveF({ event, fileData, name: specialName })}
                              chooseLabel={field.placeholder}
                              auto={field.auto}
                              multiple={field.multiple}
                              mode={field.mode ? "advanced" : "basic"}
                            />
                          </FormControl>

                        }
                      </React.Fragment>

                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {field.tooltip && (
                        <LightTooltip
                          className="form-tooltip"
                          title={field.tooltip}
                          aria-label="add"
                        >
                          <HelpIcon />
                        </LightTooltip>
                      )}
                      <label className="label-name">
                        <span className="content-name">{field.label}</span>
                      </label>
                      <Field
                        name={specialName}
                        component={renderComponent}
                        label={field.type === "date" ? "" : field.label}
                        options={field.options}
                        multiline={field.multiline}
                        rows={field.rows}
                        key={`field-${j}`}
                        subitems={field.fields}
                        type={field.type}
                        //disabled={field.disabled}
                        placeholder={field.placeholder}
                        disabled={
                          hiddenClose &&
                            specialName === hiddenClose.val &&
                            hiddenClose.state === true
                            ? false
                            : field.disabled
                        }
                        className={field.type === "date" ? "datepicker" : ""}
                      >
                        {field.typeForm === "select" && (
                          <option key={`option-${j}`} value=""></option>
                        )}
                        {field.options &&
                          field.options.map((item, k) => {
                            if (field.typeForm === "radios") {
                              return (
                                <Radio
                                  key={`radio-${k}`}
                                  value={item.value}
                                  label={item.label}
                                  checked={
                                    item.value === activateRadio ? true : false
                                  }
                                >
                                  {item.label}
                                </Radio>
                              );
                            } else if (field.typeForm === "select") {
                              return (
                                <option key={`s-option-${k}`} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            }
                          })}
                      </Field>
                    </React.Fragment>
                  )}
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }
}
